import { useMemo } from "react";
import { useSearchParams } from "@remix-run/react";
import buildTrackingParams from "~/utils/build_tracking_params";
import { appendSearchParams } from "~/utils/append_search_params";

export function useTrackingParams() {
  const [searchParams] = useSearchParams();

  const trackingParams = useMemo(() => buildTrackingParams(searchParams), [searchParams]);

  const withTrackingParams = (url: string) => {
    return appendSearchParams(url, trackingParams);
  };

  return { trackingParams, withTrackingParams };
}
