import { useContext } from "react";
import { BoardConfigurationContext } from "~/contexts/board_configuration_context";

export const useBoardConfiguration = () => {
  const boardConfiguration = useContext(BoardConfigurationContext);

  if (!boardConfiguration) {
    throw new Error(
      "useBoardConfiguration called in a context where a board configuration hasn't been provided"
    );
  }

  return boardConfiguration;
};
