export function appendSearchParams(urlOrPath: string, searchParams: URLSearchParams) {
  const paramsString = searchParams.toString();

  if (paramsString === "") {
    return urlOrPath;
  }

  if (urlOrPath.includes("?")) {
    return urlOrPath + "&" + paramsString;
  } else {
    return urlOrPath + "?" + paramsString;
  }
}
