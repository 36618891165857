const buildTrackingParams = (searchParams: URLSearchParams) => {
  const trackingParams = new URLSearchParams();

  // Keeping parity with JBEN, the t or gh_src param can be used for source tracking.
  // Here, we normalize the t param to gh_src so any links will use gh_src, since that's the
  // param that's expected by the JBEN script that builds iframes
  const mappedUrlToken = searchParams.get("gh_src") || searchParams.get("t");

  if (mappedUrlToken) {
    trackingParams.set("gh_src", mappedUrlToken);
  }

  if (searchParams.has("ccuid")) {
    trackingParams.set("ccuid", searchParams.get("ccuid")!);
  }

  return trackingParams;
};

export default buildTrackingParams;
